import React from "react";
import Radium from "radium";
import { t } from "src/modules/i18n";

import * as COLORS from "src/constants/colors";
import IMAGES, { CHATS } from "src/constants/images";

const tKey = "main.second";

const SecondBlock = () => (
  <div style={styles.wrap}>
    <div style={styles.container}>
      <div style={styles.filterWrap} />
      <div style={styles.contentContainer}>
        <div style={styles.top}>
          <img
            style={styles.imgGirl}
            srcSet={`${IMAGES.GIRL}, ${IMAGES.GIRL2X} 2x, ${IMAGES.GIRL3X} 3x`}
            src={IMAGES.GIRL}
            alt="girl"
          />
          <div style={styles.topTexts}>
            <p style={styles.topName}>Palmist Eleonora</p>
            <p style={styles.topText}>{t(`${tKey}.profession`)}</p>
          </div>
        </div>
        <div style={styles.chatList}>
          <div style={styles.row}>
            <div style={styles.date}>{t(`${tKey}.today`)} 14:26</div>
            <img
              style={styles.imgHand}
              srcSet={`${IMAGES.HAND_TERRY}, ${IMAGES.HAND_TERRY2X} 2x, ${IMAGES.HAND_TERRY3X} 3x`}
              src={IMAGES.HAND_TERRY}
              alt="hand"
            />
          </div>
          <div style={styles.row}>
            <img
              style={styles.imgChat}
              srcSet={`${CHATS.CHAT}, ${CHATS.CHAT2X} 2x, ${CHATS.CHAT3X} 3x`}
              src={CHATS.CHAT}
              alt="chat"
            />
            <div style={styles.date}>14:28</div>
          </div>
          <div style={styles.row}>
            <img
              style={styles.imgChatTwo}
              srcSet={`${CHATS.CHAT2}, ${CHATS.CHAT2_2X} 2x, ${CHATS.CHAT2_3X} 3x`}
              src={CHATS.CHAT2}
              alt="chat2"
            />
            <div style={styles.date}>14:29</div>
          </div>
        </div>
      </div>
      <div style={styles.bottomGradient} />
    </div>
  </div>
);

export default Radium(SecondBlock);

const styles = {
  wrap: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    position: "absolute",
    inset: 0,
    fontWeight: "bold",
    backgroundImage: `url(${IMAGES.BG})`,
    backgroundSize: "cover",
    zIndex: -1,
    padding: "0 28px",
  },
  container: {
    width: "100%",
    height: "100%",
    maxHeight: "67vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    borderRadius: 50,
    padding: 1,
    borderStyle: "solid",
    borderWidth: 0,
    borderImageSource: COLORS.backgroundGradientOpacity,
    borderImageSlice: 1,
    backgroundImage: `linear-gradient(to bottom, #0e293a, rgba(12, 26, 43, 0)), ${COLORS.backgroundGradientOpacity}`,
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    overflow: "hidden",
    "@media only screen and (min-width: 376px)": {
      maxHeight: "63vh",
    },
  },
  contentContainer: {
    width: "100%",
    padding: "0 25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  filterWrap: {
    position: "absolute",
    top: -30,
    width: 183,
    height: 49,
    opacity: 0.4,
    WebkitFilter: "blur(50px)",
    filter: "blur(50px)",
    backgroundColor: COLORS.secondary,
  },
  top: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px 15px",
    borderBottom: "1px solid rgba(46, 252, 237, 0.1)",
  },
  imgGirl: {
    width: 60,
    height: 60,
  },
  topTexts: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
    color: COLORS.whiteDarker,
    fontWeight: "normal",
  },
  topName: {
    fontSize: 16,
    fontWeight: "bold",
  },
  topText: {
    opacity: 0.5,
    fontSize: 12,
    letterSpacing: "-0.39px",
    color: COLORS.whiteDarker,
  },
  chatList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "5px 0",
  },
  date: {
    opacity: 0.5,
    fontSize: 10,
    fontWeight: "normal",
    letterSpacing: "-0.32px",
    color: COLORS.whiteDarker,
  },
  imgHand: {
    width: 65,
    height: 92,
    marginBottom: 15,
  },
  bottomGradient: {
    width: "100%",
    height: "30%",
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage:
      "linear-gradient(to bottom, rgba(11, 26, 43, 0), #0b1a2b 50%)",
    pointerEvents: "none",
  },
};
