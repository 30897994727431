import React, { useEffect, useState } from "react";
import Radium from "radium";
import { useSelector } from "react-redux";

import * as COLORS from "src/constants/colors";
import { APP_NAME } from "src/constants/general";
import IMAGES from "src/constants/images";
import { MAX_HEIGHT } from "src/constants/sizes";
import Analytics from "src/modules/analytics";
import { T, t } from "src/modules/i18n";
import { downloadApp } from "src/store/app/actions";

import Button from "src/components/button";
import Title from "src/components/title";

import { PAGE_STACK } from "src/store/remote-config";
import FirstBlock from "./blocks/first";
import SecondBlock from "./blocks/second";
import ThirdBlock from "./blocks/third";
import FourthBlock from "./blocks/fourth";
import AnimatedBlock from "./blocks/animated";

const tKey = "main";

const BLOCKS = {
  [PAGE_STACK.FIRST]: FirstBlock,
  [PAGE_STACK.SECOND]: SecondBlock,
  [PAGE_STACK.THIRD]: ThirdBlock,
  [PAGE_STACK.FOURTH]: FourthBlock,
  [PAGE_STACK.ANIMATED]: AnimatedBlock,
};

const MainScreen = () => {
  const { subTitle, buttonTitle, pageClickMode, autoSkip, pageStack } =
    useSelector((state) => state.remoteConfig);
  const [containerHeight, setContainerHeight] = useState(MAX_HEIGHT);

  const emptyPage = !pageStack;

  useEffect(() => {
    let timeout = null;
    if (autoSkip) {
      timeout = setTimeout(() => {
        Analytics.trackEvent("redirect", "action");
        downloadApp();
      }, autoSkip * 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [autoSkip]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setContainerHeight(window.innerHeight);
      window.scrollTo(0, 0);
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleBtnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Analytics.trackEvent("install_now", "click");
    downloadApp();
  };

  const handlePageClick = () => {
    if (pageClickMode && !emptyPage) {
      Analytics.trackEvent("page", "click");
      downloadApp();
    }
  };

  const renderBlock = () => {
    const Block = BLOCKS[pageStack];
    return <Block />;
  };

  const renderEmptyPage = () => (
    <div style={styles.wrap}>
      <Button
        buttonStyle={styles.button}
        buttonTitleStyle={styles.buttonTitleStyle}
        onClick={handleBtnClick}
        title={buttonTitle || t("_continue")}
        flare
      />
    </div>
  );

  return (
    <div onClick={handlePageClick} style={styles.wrap} aria-hidden="true">
      <main style={[styles.container, { height: containerHeight }]}>
        {emptyPage ? (
          renderEmptyPage()
        ) : (
          <>
            <div style={styles.top}>
              <div style={styles.topShadow} />
              <div style={styles.logoWrap}>
                <img style={styles.logoImg} src={IMAGES.LOGO} alt="logo" />
              </div>
              <div style={styles.topTitlesWrap}>
                <Title text={APP_NAME} style={styles.title} />
                <h3 style={styles.subTitle} className="highlighted-title">
                  {subTitle || <T k={`${tKey}.subtitle`} />}
                </h3>
              </div>
            </div>

            {renderBlock()}

            <div style={styles.bottomContainer}>
              <div style={styles.bottomPlate}>
                <span style={styles.bottomPlateOval} />
                <span style={styles.bottomPlateInner}>
                  <span>{t(`${tKey}.get_your_palm_reading`)}</span>
                </span>
              </div>
              <Button
                buttonStyle={styles.button}
                buttonTitleStyle={styles.buttonTitleStyle}
                onClick={handleBtnClick}
                title={buttonTitle || t("_continue")}
                flare
              />
              <div style={styles.buttonShadow} />
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Radium(MainScreen);

const styles = {
  wrap: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: COLORS.primaryDark,
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
  },
  top: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  topShadow: {
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "absolute",
    opacity: 0.2,
    WebkitFilter: "blur(50px)",
    filter: "blur(50px)",
    backgroundColor: COLORS.secondary,
  },
  logoWrap: {
    width: 175,
    height: 65,
    "@media only screen and (min-width: 376px)": {
      width: 249,
      height: 95,
    },
  },
  logoImg: {
    width: "100%",
    height: "100%",
  },
  topTitlesWrap: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: -15,
  },
  title: {
    fontSize: 28,
    "@media only screen and (min-width: 376px)": {
      fontSize: 40,
    },
  },
  subTitle: {
    color: COLORS.white,
    fontWeight: "normal",
    fontSize: 16,
    "@media only screen and (min-width: 376px)": {
      fontSize: 18,
    },
  },
  bottomContainer: {
    position: "absolute",
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    width: "100%",
    marginBottom: 15,
    zIndex: 2,
    "@media only screen and (min-width: 376px)": {
      marginBottom: 28,
    },
  },
  bottomPlate: {
    display: "flex",
    color: COLORS.white,
    padding: 1,
    marginBottom: 20,
    position: "relative",
    overflow: "hidden",
    zIndex: 2,
    borderRadius: 14,
    animation: "fade .8s ease-in-out",
    backgroundImage:
      "linear-gradient(to bottom, rgba(42, 252, 236, 0.14) 28%, rgba(10, 25, 43, 0))",
  },
  bottomPlateInner: {
    fontSize: 14,
    padding: "5px 13px",
    backgroundColor: COLORS.primaryDark,
    borderRadius: "inherit",
    borderColor: COLORS.transparent,
  },
  bottomPlateOval: {
    position: "absolute",
    top: -60,
    left: "50%",
    transform: "translateX(-50%)",
    width: 48,
    height: 48,
    WebkitFilter: "blur(29px)",
    filter: "blur(29px)",
    backgroundColor: "#29e2ea",
    zIndex: 1,
  },
  button: {
    maxWidth: 270,
    zIndex: 1,
  },
  buttonShadow: {
    WebkitFilter: "blur(37px)",
    filter: "blur(37px)",
    backgroundImage: COLORS.buttonGradient,
    width: "100%",
    maxWidth: 270,
    height: 37,
    bottom: -20,
    position: "absolute",
    zIndex: -1,
    pointerEvents: "none",
  },
};
