import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import * as COLORS from "../constants/colors";

const Title = ({ text, style }) => (
  <h1 style={{ ...styles.title, ...style }}>{text}</h1>
);

Title.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.any,
};

Title.defaultProps = {
  style: null,
};

export default Radium(Title);

const styles = {
  title: {
    fontSize: 40,
    fontWeight: "bold",
    color: COLORS.white,
    textAlign: "center",
    display: "flex",
  },
};
