import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";

import { Trans } from "react-i18next";
// import ar from "./locales/ar.json";
// import de from "./locales/de.json";
import en from "./locales/en.json";
// import es from "./locales/es.json";
// import fr from "./locales/fr.json";
// import hi from "./locales/hi.json";
// import it from "./locales/it.json";
// import ja from "./locales/ja.json";
// import ko from "./locales/ko.json";
// import pt from "./locales/pt.json";
// import ru from "./locales/ru.json";
// import tr from "./locales/tr.json";
// import ms from "./locales/ms.json";

export const determinantLanguage = navigator.language.split(/[-_]/)[0];

const resources = {
  // ar: {
  //   translation: ar,
  // },
  // de: {
  //   translation: de,
  // },
  en: {
    translation: en,
  },
  // es: {
  //   translation: es,
  // },
  // fr: {
  //   translation: fr,
  // },
  // hi: {
  //   translation: hi,
  // },
  // it: {
  //   translation: it,
  // },
  // ja: {
  //   translation: ja,
  // },
  // ko: {
  //   translation: ko,
  // },
  // pt: {
  //   translation: pt,
  // },
  // ru: {
  //   translation: ru,
  // },
  // tr: {
  //   translation: tr,
  // },
  // ms: {
  //   translation: ms,
  // },
};

function init() {
  const usersLng = navigator.language.split(/[-_]/)[0];

  i18next.init(
    {
      lng: usersLng,
      fallbackLng: "en",
      returnObjects: "true",
      debug: process.env.DEV,
      resources,
      react: {
        transEmptyNodeValue: "", // what to return for empty Trans
        transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
        transKeepBasicHtmlNodesFor: ["br", "strong", "i", "b"], // don't convert to <1></1> if simple react elements
      },
    },
    () => {
      // initialized and ready to go!
    }
  );
}

function t(keys, options) {
  // see https://www.i18next.com/overview/api#t
  return i18next.t(keys, options);
}

const Localization = {
  init,
  t,
};

export { Localization };
export { t };

export const T = ({ k, ...other }) => (
  <Trans i18n={i18next} i18nKey={k} {...other} />
);

T.propTypes = {
  k: PropTypes.string.isRequired,
};
