import _isString from "lodash/isString";
import _isPlainObject from "lodash/isPlainObject";
import _isArray from "lodash/isArray";
import _isBoolean from "lodash/isBoolean";
import _isNumber from "lodash/isNumber";

function parseJSONParams(paramsAsString, defaultValue) {
  try {
    return JSON.parse(paramsAsString);
  } catch (err) {
    return defaultValue;
  }
}

function convertType(value, defaultValue) {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  if (_isString(defaultValue)) {
    return value || defaultValue;
  }
  if (_isPlainObject(defaultValue) || _isArray(defaultValue)) {
    return parseJSONParams(value, defaultValue);
  }
  if (_isBoolean(defaultValue)) {
    return !!value;
  }
  if (_isNumber(defaultValue)) {
    return value;
  }
  return value;
}

export function createRemoteConfigSnapshotNormalizer(
  defaultValues,
  enableLogger = false
) {
  return function (snapshot) {
    if (enableLogger) {
      console.log("Normalizer: param names", Object.keys(defaultValues));
      console.log("Normalizer: snapshot", snapshot);
    }

    const remoteConfig = Object.keys(defaultValues).reduce(
      (convertedData, paramName) => {
        // eslint-disable-next-line no-param-reassign
        convertedData[paramName] = convertType(
          snapshot[paramName],
          defaultValues[paramName]
        );
        return convertedData;
      },
      {}
    );

    if (enableLogger) {
      console.log("Normalizer: normalized remoteConfig", remoteConfig);
    }

    return { remoteConfig, snapshot };
  };
}
