import React from "react";
import Radium from "radium";

import IMAGES from "src/constants/images";

import Palm from "src/components/palm";

const FirstBlock = () => (
  <div style={styles.wrap}>
    <Palm style={styles.palm} itemStyle={styles.item} />
    <div style={styles.bottomGradient} />
  </div>
);

export default Radium(FirstBlock);

const styles = {
  wrap: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    inset: 0,
    fontWeight: "bold",
    backgroundImage: `url(${IMAGES.BG})`,
    backgroundSize: "cover",
    zIndex: -1,
  },
  palm: {
    width: "100%",
    maxWidth: 375,
    zIndex: 3,
  },
  item: {
    fontSize: 16,
    "@media only screen and (min-width: 376px)": {
      fontSize: 22,
    },
  },
  bottomGradient: {
    width: "100%",
    height: "30%",
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage:
      "linear-gradient(to bottom, rgba(11, 26, 43, 0), #0b1a2b 50%)",
    pointerEvents: "none",
  },
};
