export const black = "#000000";
export const white = "#ffffff";
export const transparent = "transparent";

export const primary = "#2efced";
export const secondary = "#33ffec";
export const primaryDark = "#0a182b";
export const whiteDarker = "#ecfaff";

export const buttonGradient = `linear-gradient(100deg, #01e5cd 1%, #b326e8 90%)`;
export const buttonGradientInactive = `linear-gradient(99deg, #b8b9c3, #cfd0d5)`;
export const backgroundGradient = `linear-gradient(to bottom, #22304e 0%, #364b6c 55%)`;
export const backgroundGradientOpacity = `linear-gradient(to bottom, rgba(42, 252, 237, 0.15) 1%, rgba(10, 25, 43, 0))`;
