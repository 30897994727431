import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";

import * as COLORS from "src/constants/colors";

const Circle = ({ isSmall }) => (
  <div style={[styles.itemCircle, isSmall ? styles.itemCircleSmall : null]}>
    <span style={styles.itemCircleFilter} />
    <span style={styles.itemCircleMiddle} />
    <span style={styles.itemCircleCenter} />
  </div>
);

Circle.propTypes = {
  isSmall: PropTypes.bool,
};

Circle.defaultProps = {
  isSmall: false,
};

export default Radium(Circle);

const styles = {
  itemCircle: {
    position: "relative",
    width: 10,
    height: 10,
    minWidth: 10,
    margin: "0 7px",
    transform: "translate(0, -50%)",
    borderRadius: "50%",
    "@media only screen and (min-width: 376px)": {
      width: 15,
      minWidth: 15,
      height: 15,
    },
  },
  itemCircleSmall: {
    width: 8,
    height: 8,
    minWidth: 8,
    margin: "0 5px",
    "@media only screen and (min-width: 376px)": {
      width: 10,
      height: 10,
      minWidth: 10,
    },
  },
  itemCircleFilter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary,
    WebkitFilter: "blur(13px)",
    filter: "blur(13px)",
  },
  itemCircleMiddle: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary,
    position: "absolute",
    borderRadius: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: 0.5,
  },
  itemCircleCenter: {
    width: "50%",
    height: "50%",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: COLORS.primary,
  },
};
