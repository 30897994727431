import queryString from 'query-string';
import EvTruck from '@magnus/react-native-evtruck';
import Session from '@wowmaking/react-native-analytics/core/helpers/session';

const Analytics = {
  init() {
    EvTruck.init({
      token: process.env.REACT_APP_MAGNUS_TOKEN,
    });

    return Session.init(this).then(() => {
      const p = queryString.parse(window.location.search);
      this.setUserProperty('testmode', p.testmode);

      if (p.utm_source) {
        this.setUserProperty('utm_source', p.utm_source);
        this.setUserProperty('utm_medium', p.utm_medium);
        this.setUserProperty('utm_campaign', p.utm_campaign);
        this.setUserProperty('utm_adset', p.utm_adset);
        this.setUserProperty('utm_creative', p.utm_creative);
        this.setUserProperty('utm_content', p.utm_content);
        this.setUserProperty('utm_term', p.utm_term);
      }
    });
  },

  setUserProperty(name, value) {
    EvTruck.setUserProperty(name, value);
  },

  trackEvent(category, action, params) {
    const event = category + '_' + action;
    EvTruck.trackEvent(event, params);
    window.dataLayer?.push({ event, ...params }); // GTM
  },
};

export default Analytics;
