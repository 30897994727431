import FINANCE from "../assets/icons/finance.svg";
import HEALTH from "../assets/icons/health.svg";
import LOVE from "../assets/icons/love.svg";
import SUITCASE from "../assets/icons/suitcase.svg";

export default {
  FINANCE,
  HEALTH,
  LOVE,
  SUITCASE,
};
