import React from "react";
import Radium from "radium";
import Lottie from "lottie-react-web";

import * as COLORS from "src/constants/colors";
import IMAGES from "src/constants/images";
import ANIMATIONS from "src/constants/animations";

const AnimatedBlock = () => (
  <div style={styles.wrap}>
    <Lottie
      style={styles.animationStars}
      options={{
        animationData: ANIMATIONS.STARS,
      }}
    />
    <div style={styles.cloudsWrap} />
    <div style={styles.mountains} />
    <div style={styles.layerGradient} />

    <Lottie
      style={styles.animationPalm}
      options={{
        animationData: ANIMATIONS.HAND,
        loop: false,
      }}
    />
    <div style={styles.bottomGradient} />
  </div>
);

export default Radium(AnimatedBlock);

const styles = {
  wrap: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    position: "absolute",
    inset: 0,
    fontWeight: "bold",
    backgroundImage: COLORS.backgroundGradient,
    zIndex: -1,
  },
  cloudsWrap: {
    width: 1364,
    height: 204,
    position: "absolute",
    inset: 0,
    top: 60,
    backgroundRepeat: "repeat-x",
    backgroundImage: `url(${IMAGES.CLOUDS})`,
    backgroundSize: "contain",
    animation: "slide 40s linear infinite",
  },
  mountains: {
    width: "100%",
    height: "100%",
    position: "absolute",
    inset: 0,
    backgroundImage: `url(${IMAGES.MOUNTAINS})`,
    backgroundPosition: "center 100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  layerGradient: {
    backgroundImage:
      "linear-gradient(to bottom, rgba(1, 5, 24, 0.62), rgba(0, 12, 19, 0.64) 45%, #0a192b 96%, rgba(22, 66, 84, 0.75) 121%)",
    position: "absolute",
    inset: 0,
    zIndex: 1,
  },
  animationStars: {
    width: "100%",
    height: "auto",
    position: "absolute",
    top: 0,
  },
  animationPalm: {
    width: "100%",
    height: "auto",
    position: "absolute",
    zIndex: 2,
  },
  bottomGradient: {
    width: "100%",
    height: "30%",
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage:
      "linear-gradient(to bottom, rgba(11, 26, 43, 0), #0b1a2b 50%)",
    pointerEvents: "none",
  },
};
