export const cacheImages = async (srcArray) => {
  const promises = await srcArray.map(
    (src) =>
      new Promise((res, rej) => {
        const img = new Image();

        img.src = src;
        img.onload = res();
        img.onerror = rej();
      })
  );

  const isLoaded = await Promise.all(promises);

  return isLoaded;
};
