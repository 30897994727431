import { SET } from "./types";

export const PAGE_STACK = {
  FIRST: "first",
  SECOND: "second",
  THIRD: "third",
  FOURTH: "fourth",
  ANIMATED: "animated",
};

export const initialState = {
  buttonTitle: "",
  pageClickMode: true,
  autoSkip: 30, // in seconds
  pageStack: null,
  subTitle: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };

    default:
      return state;
  }
}
