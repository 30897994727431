import { createAction } from "redux-actions";

import RemoteConfig from "../../modules/remote-config";

import { SET } from "./types";
import { initialState } from "./index";

const REMOTE_CONFIG_CACHE_TIME = 60;

const set = createAction(SET, (cfg) => ({ cfg }));

export function init() {
  return (dispatch) => dispatch(fetchRemoteConfig());
}

export function fetchRemoteConfig() {
  return (dispatch, getState) =>
    RemoteConfig.fetch(initialState, REMOTE_CONFIG_CACHE_TIME)
      .then((cfg) => {
        dispatch(set(cfg));
      })
      .catch((err) => {
        console.warn("fetchRemoteConfig err: ", err.message);
      })
      .then(() => getState().remoteConfig);
}
