import React from "react";
import Radium from "radium";

import Analytics from "src/modules/analytics";

import IMAGES from "src/constants/images";
import Item from "./item";

const FourthBlock = () => {
  const handleItemClick = (id) => {
    Analytics.trackEvent("fourth_item", "click", { id });
  };

  return (
    <div style={styles.wrap}>
      <div style={styles.itemList}>
        <Item id="palm" onClick={handleItemClick} />
        <Item id="daily" onClick={handleItemClick} />
      </div>
      <div style={styles.bottomGradient} />
    </div>
  );
};

export default Radium(FourthBlock);

const styles = {
  wrap: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    inset: 0,
    fontWeight: "bold",
    backgroundImage: `url(${IMAGES.BG})`,
    backgroundSize: "cover",
    zIndex: -1,
    padding: "0 28px",
  },
  itemList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomGradient: {
    width: "100%",
    height: "30%",
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage:
      "linear-gradient(to bottom, rgba(11, 26, 43, 0), #0b1a2b 50%)",
    pointerEvents: "none",
  },
};
