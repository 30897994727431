import React from "react";
import Radium from "radium";
import { t } from "src/modules/i18n";
import PropTypes from "prop-types";

import * as COLORS from "src/constants/colors";
import IMAGES from "src/constants/images";

import Palm from "src/components/palm";

const tKey = "main.fourth";

const Item = ({ id, onClick }) => {
  const handleItemClick = (e) => {
    e.preventDefault();
    onClick(id);
  };

  const renderImg = () => {
    const img =
      id === "palm" ? (
        <Palm style={styles.imgPalm} isSmall />
      ) : (
        <img src={IMAGES.SIGNS} style={styles.imgSigns} alt="signs" />
      );

    return <div style={styles.itemImgWrap}>{img}</div>;
  };

  return (
    <div
      style={styles.item}
      onClick={handleItemClick}
      role="button"
      aria-hidden="true"
    >
      {renderImg()}
      <div style={styles.itemTexts}>
        <span style={styles.itemDivider} />
        <h4 style={styles.itemTitle}>{t(`${tKey}.${id}.title`)}</h4>
        <p style={styles.itemText}>{t(`${tKey}.${id}.text`)}</p>
      </div>
    </div>
  );
};

Item.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default Radium(Item);

const styles = {
  item: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: 20,
    height: 120,
    paddingRight: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "rgba(42, 252, 237, 0.1)",
    backgroundImage: `linear-gradient(to right, #0e293a 16%, rgba(12, 26, 43, 0))`,
    margin: "18px 0",
    "@media only screen and (min-width: 376px)": {
      paddingRight: 24,
      height: 140,
    },
  },
  itemImgWrap: {
    position: "relative",
    width: "53%",
    height: "100%",
  },
  imgSigns: {
    position: "absolute",
    left: -64,
    top: -40,
    width: 215,
    "@media only screen and (min-width: 376px)": {
      width: 255,
      top: -55,
    },
  },
  imgPalm: {
    position: "absolute",
    left: -27,
    top: -45,
    width: 148,
    "@media only screen and (min-width: 376px)": {
      width: 178,
      top: -54,
    },
  },
  itemTexts: {
    display: "flex",
    flexDirection: "column",
  },
  itemDivider: {
    width: 20,
    height: 2,
    backgroundColor: COLORS.primary,
  },
  itemTitle: {
    marginTop: 10,
    fontSize: 16,
    letterSpacing: "-0.64px",
    color: COLORS.whiteDarker,
    "@media only screen and (min-width: 376px)": {
      marginTop: 20,
      fontSize: 20,
    },
  },
  itemText: {
    fontSize: 12,
    opacity: 0.5,
    fontWeight: "normal",
    letterSpacing: "-0.45px",
    color: COLORS.whiteDarker,
    "@media only screen and (min-width: 376px)": {
      fontSize: 14,
    },
  },
};
