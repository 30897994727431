import React from "react";
import Radium from "radium";
import { slideInLeft } from "react-animations";

export default Radium(() => (
  <div style={styles.ct}>
    <div style={styles.flare} />
  </div>
));

const styles = {
  ct: {
    position: "relative",
    left: -200,
    display: "block",
    width: 800,
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationName: Radium.keyframes(slideInLeft, "slideInLeft"),
  },
  flare: {
    background: "#fff",
    width: 32,
    height: 200,
    top: -100,
    right: 0,
    opacity: 0.1,
    position: "absolute",
    transform: "rotate(35deg)",
  },
};
