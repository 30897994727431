import BG from "../assets/images/bg-main.png";

import PALM from "../assets/images/palm.svg";

import LOGO from "../assets/images/logo/logo.svg";

import CLOUDS from "../assets/images/clouds/clouds.png";
import CLOUDS2X from "../assets/images/clouds/clouds@2x.png";
import CLOUDS3X from "../assets/images/clouds/clouds@3x.png";

import MOUNTAINS from "../assets/images/mountains/mountains.png";

import GIRL from "../assets/images/girl/girl.png";
import GIRL2X from "../assets/images/girl/girl@2x.png";
import GIRL3X from "../assets/images/girl/girl@3x.png";

import HAND_TERRY from "../assets/images/hand-terry/hand-terry.png";
import HAND_TERRY2X from "../assets/images/hand-terry/hand-terry@2x.png";
import HAND_TERRY3X from "../assets/images/hand-terry/hand-terry@3x.png";

import CHAT from "../assets/images/chats/chat.png";
import CHAT2X from "../assets/images/chats/chat@2x.png";
import CHAT3X from "../assets/images/chats/chat@3x.png";

import CHAT2 from "../assets/images/chats/chat-2.png";
import CHAT2_2X from "../assets/images/chats/chat-2@2x.png";
import CHAT2_3X from "../assets/images/chats/chat-2@3x.png";

import SIGNS from "../assets/images/signs.svg";

export const CHATS = {
  CHAT,
  CHAT2X,
  CHAT3X,
  CHAT2,
  CHAT2_2X,
  CHAT2_3X,
};

export default {
  BG,
  LOGO,
  MOUNTAINS,
  CLOUDS,
  CLOUDS2X,
  CLOUDS3X,
  GIRL,
  GIRL2X,
  GIRL3X,
  HAND_TERRY,
  HAND_TERRY2X,
  HAND_TERRY3X,
  SIGNS,
  PALM,
};
