import React from "react";
import Loader from "react-loader-spinner";
import Radium from "radium";
import { compose } from "recompose";

import { primary, primaryDark } from "../constants/colors";

export default compose(Radium)(() => (
  <div style={styles.root}>
    <Loader style={styles.spinner} type="Audio" color={primary} />
  </div>
));

const styles = {
  root: {
    backgroundColor: primaryDark,
    width: "100vw",
    height: "100vh",
    display: "flex",
    position: "fixed",
    left: 0,
    top: 0,
  },
  spinner: {
    margin: "auto",
  },
};
