import React, { useCallback } from "react";
import Radium from "radium";

import Analytics from "src/modules/analytics";
import IMAGES from "src/constants/images";
import ICONS from "src/constants/icons";

import Item from "./item";

const ITEMS = [
  {
    key: "finance",
    icon: ICONS.FINANCE,
  },
  {
    key: "business",
    icon: ICONS.SUITCASE,
  },
  {
    key: "love",
    icon: ICONS.LOVE,
  },
  {
    key: "health",
    icon: ICONS.HEALTH,
  },
];

const ThirdBlock = () => {
  const handleItemClick = useCallback((id) => {
    Analytics.trackEvent("third_item", "click", { id });
  }, []);

  return (
    <div style={styles.wrap}>
      <div style={styles.imgWrap}>
        <img style={styles.imgSigns} src={IMAGES.SIGNS} alt="signs" />
      </div>
      <div style={styles.itemList}>
        {ITEMS.map((item) => (
          <Item key={item.key} item={item} onClick={handleItemClick} />
        ))}
      </div>

      <div style={styles.bottomGradient} />
    </div>
  );
};

export default Radium(ThirdBlock);

const styles = {
  wrap: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    inset: 0,
    fontWeight: "bold",
    backgroundImage: `url(${IMAGES.BG})`,
    backgroundSize: "cover",
    zIndex: -1,
    padding: "0 28px",
  },
  imgWrap: {
    width: "90%",
    marginTop: -30,
    "@media only screen and (min-width: 376px)": {
      marginTop: 0,
      width: "100%",
    },
  },
  imgSigns: {
    width: "100%",
  },
  itemList: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: -30,
    "@media only screen and (min-width: 376px)": {
      marginTop: -10,
    },
  },
  bottomGradient: {
    width: "100%",
    height: "30%",
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage:
      "linear-gradient(to bottom, rgba(11, 26, 43, 0), #0b1a2b 50%)",
    pointerEvents: "none",
  },
};
