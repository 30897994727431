import { createAction } from "redux-actions";
import Attribution from '@wowmaking/web2app';

import Analytics from "src/modules/analytics";
import { APPSFLYER_LINK } from "src/constants/general";
import { init as initRemoteConfig } from "../remote-config/actions";
import { SET_LOADED } from "./types";

const setLoaded = createAction(SET_LOADED);

export function load() {
  return function (dispatch) {
    Attribution.init();
    Analytics.init()
      .then(() => dispatch(initRemoteConfig()))
      .then(() => dispatch(setLoaded()))
      .then(() => Analytics.trackEvent("page", "loaded"));
  };
}

export function downloadApp() {
  const link = Attribution.addAttributionToLink(APPSFLYER_LINK);
  Analytics.trackEvent("install_app", "click");
  window.location.href = link;
}
