import React from "react";
import Radium from "radium";
import { t } from "src/modules/i18n";
import PropTypes from "prop-types";

import * as COLORS from "src/constants/colors";
import IMAGES from "src/constants/images";

import Circle from "./circle";

const tKey = "main.palm";

const Palm = ({ style, itemStyle, isSmall }) => (
  <div style={[styles.wrap, style]}>
    <img src={IMAGES.PALM} alt="palm" style={styles.palmImg} />
    <div
      style={[
        styles.item,
        itemStyle,
        !isSmall ? styles.itemFirst : styles.itemFirstSmall,
      ]}
    >
      <div style={styles.itemTexts}>
        <h4 style={styles.itemTitle}>{t(`${tKey}.0.title`)}</h4>
        <p style={styles.itemText}>{t(`${tKey}.0.text`)}</p>
      </div>
      <Circle isSmall={isSmall} />
    </div>
    <div
      style={[
        styles.item,
        itemStyle,
        !isSmall ? styles.itemSecond : styles.itemSecondSmall,
      ]}
    >
      <Circle isSmall={isSmall} />
      <div style={styles.itemTexts}>
        <h4 style={styles.itemTitle}>{t(`${tKey}.1.title`)}</h4>
        <p style={styles.itemText}>{t(`${tKey}.1.text`)}</p>
      </div>
    </div>
    <div
      style={[
        styles.item,
        itemStyle,
        styles.itemThird,
        !isSmall ? styles.itemThird : styles.itemThirdSmall,
      ]}
    >
      <Circle isSmall={isSmall} />
      <div style={styles.itemTexts}>
        <h4 style={styles.itemTitle}>{t(`${tKey}.2.title`)}</h4>
        <p style={styles.itemText}>{t(`${tKey}.2.text`)}</p>
      </div>
    </div>
  </div>
);

Palm.propTypes = {
  style: PropTypes.any,
  itemStyle: PropTypes.any,
  isSmall: PropTypes.bool,
};

Palm.defaultProps = {
  style: null,
  itemStyle: null,
  isSmall: false,
};

export default Radium(Palm);

const styles = {
  wrap: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
  },
  palmImg: {
    width: "100%",
  },
  item: {
    position: "absolute",
    top: "57%",
    left: "58%",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
  },
  itemFirst: {
    left: "auto",
    right: "66%",
    textAlign: "right",
  },
  itemFirstSmall: {
    left: "auto",
    top: "77%",
    right: "49%",
    textAlign: "right",
  },
  itemSecond: {
    top: "40%",
  },
  itemSecondSmall: {
    top: "27%",
    left: "50%",
  },
  itemThird: {
    top: "69%",
  },
  itemThirdSmall: {
    left: "68%",
  },
  itemTexts: {
    display: "flex",
    flexDirection: "column",
  },
  itemTitle: {
    letterSpacing: "-0.71px",
    color: COLORS.primary,
  },
  itemText: {
    fontSize: 8,
    opacity: 0.65,
    fontWeight: "normal",
    letterSpacing: "-0.39px",
    color: COLORS.primary,
    "@media only screen and (min-width: 420px)": {
      fontSize: 12,
    },
  },
};
