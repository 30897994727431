import React, { forwardRef } from "react";
import Radium from "radium";
import { compose } from "recompose";
import {
  white,
  buttonGradient,
  buttonGradientInactive,
} from "src/constants/colors";
import Flare from "./flare";

export default compose(
  Radium,
  forwardRef
)(
  (
    {
      onClick,
      title,
      subTitle,
      disabled,
      flare,
      buttonStyle,
      buttonTitleStyle,
      buttonSubtitleStyle,
      contentContainerStyle,
      children,
      iconRight,
      ...other
    },
    ref
  ) => (
    <button
      {...other}
      type="button"
      ref={ref}
      onClick={onClick}
      style={[styles.button, buttonStyle, disabled ? styles.disabled : null]}
      disabled={disabled}
    >
      <div style={contentContainerStyle}>
        <p style={[styles.buttonTitle, buttonTitleStyle]}>{title}</p>
        {subTitle ? (
          <p style={[styles.buttonSubtitle, buttonSubtitleStyle]}>{subTitle}</p>
        ) : null}
        {iconRight}
        {!disabled && flare ? <Flare /> : null}
      </div>
      {children}
    </button>
  )
);

const styles = {
  button: {
    backgroundImage: buttonGradient,
    border: "0",
    minHeight: 50,
    borderRadius: 50,
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
    transitionProperty: "opacity",
    ":focus": {
      outline: 0,
    },
    ":active": {
      opacity: 0.75,
    },
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
  },
  buttonTitle: {
    fontSize: 20,
    fontWeight: "bold",
    letterSpacing: "1.05px",
    color: white,
  },
  buttonSubtitle: {
    fontSize: 15,
    color: white,
  },
  disabled: {
    backgroundImage: buttonGradientInactive,
  },
};
