import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { StyleRoot } from "radium";

import { cacheImages } from "src/utils/images";

import Loading from "src/components/loader";
import IMAGES from "src/constants/images";
import Styles from "../styles";
import Main from "./main";

export default compose(
  connect((state) => ({
    loaded: state.app.loaded,
  }))
)(({ loaded }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      const imgs = Object.values(IMAGES);
      await cacheImages(imgs);
      setIsLoading(false);
    };
    fetchImages();
  }, []);

  return (
    <StyleRoot className="fill-available">
      <Styles />
      {loaded && !isLoading ? <Main /> : <Loading />}
    </StyleRoot>
  );
});
