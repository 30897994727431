import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";

import { t } from "src/modules/i18n";

import * as COLORS from "src/constants/colors";

const tKey = "main.third";

const Item = ({ item, onClick }) => {
  const handleItemClick = (e) => {
    e.preventDefault();
    onClick(item.key);
  };

  return (
    <div
      style={styles.item}
      onClick={handleItemClick}
      role="button"
      aria-hidden="true"
    >
      <div style={styles.itemIconWrap}>
        <img src={item.icon} alt={item.key} />
      </div>
      <span style={styles.itemText}>{t(`${tKey}.${item.key}`)}</span>
    </div>
  );
};

Item.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default Radium(Item);

const styles = {
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(50% - 7px)",
    borderRadius: 20,
    height: 40,
    padding: 1,
    borderStyle: "solid",
    borderWidth: 0,
    borderImageSource: COLORS.backgroundGradientOpacity,
    borderImageSlice: 1,
    backgroundImage: `linear-gradient(to bottom, #0e293a, rgba(12, 26, 43, 0)), ${COLORS.backgroundGradientOpacity}`,
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    margin: "7px 0",
    cursor: "pointer",
  },
  itemIconWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 25,
    height: 16,
  },
  itemText: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 10,
    letterSpacing: "-0.51px",
    color: COLORS.whiteDarker,
  },
};
